import React from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"
// import Header from "./Header/Header"
// import HubspotCustomForm from "./HubspotCustomForm/HubspotCustomForm"
import style from "./PerimeterForm.module.scss"
import PerimeterHeroSection from "./perimeterHeroSection/PerimeterHeroSection"
import PerimeterStepsTimeline from "./perimeterStepsTimeline/PerimeterStepsTimeline"
import PerimeterSteps from "./perimeterSteps/PerimeterSteps"
const PerimeterForm = props => {
  return (
    <div className={style.perimeterContainer}>
      <PerimeterHeroSection />
      <PerimeterStepsTimeline />
      <Container>
        <div className={style.stepTitle}>
          <div className={style.stepNumber}>
            <p>1</p>
          </div>
          <h2>
            Pour une solution qui vous correspond, <br></br>
            <span>choisissez parmi nos 5 produits</span>, et leurs
            fonctionnalités respectives.
          </h2>
        </div>
        {/* <Header {...props} />
        <HubspotCustomForm {...props} /> */}
        <PerimeterSteps />
      </Container>
    </div>
  )
}

PerimeterForm.propTypes = {
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired,
  hubspotForm: PropTypes.object.isRequired,
  formTitle: PropTypes.string
}
export default PerimeterForm
