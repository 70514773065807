import React from "react"
import style from "./PerimeterSteps.module.scss"

import CMImg from "./assets/perimeterCashManagement.svg"
import CFPImg from "./assets/perimeterCashFlowPlanning.svg"
import CARAImg from "./assets/perimeterAccountsReceivableAutomation.svg"
import CAPImg from "./assets/perimeterAccountsPayableAutomation.svg"
import SwitchButton from "../../Atoms/SwitchButton/SwitchButton"
import CheckBox from "../../Atoms/Checkbox/Checkbox"

function PerimeterSteps() {
  const perimeterScopes = [
    {
      id: 1,
      switch: false,
      title: `Banking and ERP Connectivity`,
      subtitle: `Améliorez la communication bancaire pour l'agrégation et les paiements`,
      scopes: [
        {
          id: 1.1,
          title: `Agrégation bancaire`,
          subtitle: `Récupération des relevés bancaires, et transfert vers l'ERP`
        },
        {
          id: 1.2,
          title: `Récupération encours`,
          subtitle: `Récupération des encours (factures, devis...) depuis l'ERP`
        },
        {
          id: 1.3,
          title: `Paiement`,
          subtitle: `Règles de validation, double signature, validation mobile`
        }
      ]
    },
    {
      id: 2,
      switch: true,
      img: CMImg,
      title: `Cash Management`,
      subtitle: `Anticipez et optimisez les positions de trésorerie à court terme`,
      scopes: [
        {
          id: 2.1,
          title: `Situation de trésorerie`,
          subtitle: `Agrégation bancaire et prévisions à court terme pour suivre les positions de trésorerie`
        },
        {
          id: 2.2,
          title: `Cash pooling`,
          subtitle: `Gestion des structures de cash pooling, transferts d'équilibrage, suggestion automatique d'équilibrages`
        },
        {
          id: 2.3,
          title: `Prévisions 13 semaines`,
          subtitle: `Anticipation de l’évolution de la trésorerie semaine après semaine pour les 3 mois à venir`
        }
      ]
    },
    {
      id: 3,
      switch: true,
      img: CFPImg,
      title: `Cash Flow Planning`,
      subtitle: `Planifiez la trésorerie à long terme pour assurer le financement de la croissance`,
      scopes: [
        {
          id: 3.1,
          title: `Prévisions de trésorerie`,
          subtitle: `Plan de trésorerie, analyse des écarts au réel, planification de scénarios...`
        },
        {
          id: 3.2,
          title: `Convertisseur P&L-to-cash`,
          subtitle: `Transformation du compte de résultat en prévisionnel de trésorerie`
        },
        {
          id: 3.3,
          title: `Gestion de la dette`,
          subtitle: `Gestion Cap/Floor/Swap, taux fixe/variable/leasing, indices Euribor/Ester/ECB`
        },
        {
          id: 3.4,
          title: `Placements financiers`,
          subtitle: `Identification d'opportunités d'investissement optimisées, suivi du portefeuille de dépôts à terme`
        }
      ]
    },
    {
      id: 4,
      switch: true,
      img: CARAImg,
      title: `Accounts Payable Automation`,
      subtitle: `Structurez les processus de gestion des dépenses de l'entreprise`,
      scopes: [
        {
          id: 4.1,
          title: `Bons de commande`,
          subtitle: `Intégration des bons de commande, rapprochement avec les factures fournisseurs`
        },
        {
          id: 4.2,
          title: `Factures fournisseurs`,
          subtitle: `Collecte par email/cloud/papier, OCR, workflows de vérification`
        },
        {
          id: 4.3,
          title: `Cartes d’entreprise`,
          subtitle: `Cartes physiques/virtuelles, restrictions personnalisées, automatisation de la collecte des reçus`
        },
        {
          id: 4.4,
          title: `Pré-comptabilité journal d’achat`,
          subtitle: `Automatisation de l'affectation des comptes comptables, centres de coûts, codes analytiques`
        }
      ]
    },
    {
      id: 5,
      switch: true,
      img: CAPImg,
      title: `Accounts Receivable Automation`,
      subtitle: `Réduisez le DSO grâce à une meilleure gestion du poste client`,
      scopes: [
        {
          id: 5.1,
          title: `Analyse du DSO`,
          subtitle: `Suivi du DSO au niveau global, filiale, ou client, et impact sur le prévisionnel`
        },
        {
          id: 5.2,
          title: `Relance et recouvrement`,
          subtitle: `Relance par email/lettres recommandées/appel, séquences personnalisées automatisées`
        }
      ]
    }
  ]

  return (
    <div className={style.stepsContainer}>
      {perimeterScopes.map(item => (
        <div key={item.id} className={style.stepsCard}>
          <div className={style.cardContent}>
            <div className={style.cardHeader}>
              <div className={style.cardTitle}>
                {item.switch && <SwitchButton id={item.id} />}
                <h3>{item.title}</h3>
                {item.switch === false && (
                  <span>
                    <p>Mandatory</p>
                  </span>
                )}
              </div>
              <p>{item.subtitle}</p>
            </div>
            <div className={style.optionsWrapper}>
              {item.scopes.map(option => (
                <div key={option.id} className={style.optionCard}>
                  <CheckBox id={option.id} />
                  <div className={style.optionTexts}>
                    <p className={style.optionTitle}>{option.title}</p>
                    <p className={style.optionSubtitle}>{option.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {item.img && (
            <div className={style.stepsImgContainer}>
              <img src={item.img} alt={item.title} />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default PerimeterSteps
