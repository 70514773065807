import React from "react"
import PropTypes from "prop-types"

import style from "./SwitchButton.module.scss"

function SwitchButton({ id }) {
  return (
    <label className={style.switch} id={id}>
      <input type="checkbox" className={style.tcheckBox}></input>
      <span className={`${style.slider} + ${style.round}`}></span>
    </label>
  )
}

SwitchButton.propTypes = {
  id: PropTypes.any.isRequired
}

export default SwitchButton
