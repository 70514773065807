import React from "react"
import style from "./PerimeterHeroSection.module.scss"
import { Container } from "react-bootstrap"
import CashPositioningFR from "../../../images/CashPositioningFR.svg"

function PerimeterHeroSection() {
  return (
    <div className={style.heroSectionContainer}>
      <Container>
        <div className={style.topWrapper}>
          <div className={style.text}>
            <h1>
              Bienvenue sur le <span>configurateur</span>
              <br></br> de votre offre
            </h1>
            <p className={style.description}>
              Nous avons besoin de quelques précisions pour préparer une
              proposition adaptée à vos besoins. L’opération se déroule en 2
              étapes.
            </p>
          </div>
          <img
            src={CashPositioningFR}
            alt="Perimeter header"
            className={style.img}
          />
        </div>
      </Container>
    </div>
  )
}

export default PerimeterHeroSection
