import React from "react"
import PropTypes from "prop-types"

import style from "./Checkbox.module.scss"

function CheckBox({ id }) {
  return (
    <label className={style.container} id={id}>
      <input type="checkbox"></input>
      <span className={style.checkMark}></span>
    </label>
  )
}

CheckBox.propTypes = {
  id: PropTypes.any.isRequired
}

export default CheckBox
