import React from "react"
import style from "./PerimeterStepsTimeline.module.scss"
import { Container } from "react-bootstrap"

function PerimeterStepsTimeline() {
  return (
    <Container>
      <div className={style.bottomWrapper}>
        <hr className={style.dottedLine}></hr>
        <div className={style.step}>
          <div className={style.numberSquare}>
            <p>1</p>
          </div>
          <div>
            <p className={style.stepTitle}>Le choix des produits</p>
            <p>
              Sélectionnez les produits qui correspondent à vos besoin, comme
              évoqué lors de la démonstration
            </p>
          </div>
        </div>
        <div className={style.step}>
          <div className={style.numberSquare}>
            <p>2</p>
          </div>
          <div>
            <p className={style.stepTitle}>Vos informations</p>
            <p>
              Remplissez les informations de votre entreprises pour nous aider à
              calibrer l’offre corectement.
            </p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default PerimeterStepsTimeline
